.chooseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}
.chooseContainer p {
  margin-right: 20px;
}

.allCodes {
  background-color: #e0e0e0;
  width: 40%;
  padding: 20px;
  min-width: 300px;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 20px;
}

.allCodes::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 5px;
}

.allCodes::-webkit-scrollbar-track {
  background: transparent;
  width: 1px;
  border-radius: 5px;
}

.allCodes::-webkit-scrollbar-thumb {
  /* background: rgba(38, 214, 170, 1);
  width: 20px; */
  border-radius: 5px;
}

.allCodes p {
  margin-bottom: 5px;
  margin-top: 5px;
}
.copyCodes {
  width: 40%;
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}
