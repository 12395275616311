@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: "Montserrat", sans-serif;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  box-shadow: none !important;
}

.modal-open {
  overflow: scroll !important;
}

.btn.btn-error {
  background-color: #B22222;
  border-color: #B22222;
  color: white;
}

.btn.btn-outline-error {
  background-color: transparent;
  border-color: #B22222;
  color: #B22222;
}

.select__input {
  background-color: unset !important;
}
* {
  outline: 0;
}

main {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
}

.zeitContainer {
  margin-top: 2.5%;
}

.zeitContainer p {
  margin-bottom: 0.1rem;
  font-size: 15px;
}

.zeitBtn {
  border: 1px solid rgba(0, 0, 0, 0.6);
  background-color: rgba(242, 242, 242, 1);
  font-size: 15px;
  padding-left: 1rem;
  padding-right: 4rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.zeitIcon {
  margin-right: 3rem;
  margin-top: -1.5%;
}

.mainContainer {
  width: 100%;
  margin-top: 3%;
}

.statBox {
  width: 90%;
  height: 450px;
  margin-top: 60px;
  padding-right: 10px;
}
.statBox h4 {
  font-size: 18px;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  display: none !important;
}
.DayPickerKeyboardShortcuts_panel {
  display: none !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::after {
  display: none !important;
}

.statMainBox {
  background-color: rgba(227, 227, 227, 0.8);
  width: 100%;

  margin-bottom: 3%;
}

.downBtn {
  float: right;
  margin-top: 0.4rem;
  margin-right: 0.5rem;
}
.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.btnExport {
  font-size: 16px;
  border-radius: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.btnIcon {
  margin-right: 10px;
  margin-top: -3px;
}

.btnIconDl {
  margin-left: 15px;
  margin-top: -3px;
}

.bg-dark {
  background-color: white !important;
}

.navContainer {
  padding-top: 0.7rem;
}

a {
  color: black !important;
}

.navbar-dark .navbar-toggler {
  color: black !important;
  border-color: black !important;
  border-width: 1.25px !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

button:focus {
  outline: none;
}

.navRight {
  margin-right: auto;
}

.navbar {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 2rem !important;
}

.navLinks {
  padding: 0.8rem;
  font-size: 16px;
}

.navLinks:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .navLinks {
    padding-top: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.activeLink {
  font-weight: 600;
}

main {
  width: 70%;
}

.benutzerAnlegenBtn {
  border-radius: 0;
  font-weight: 600;
  margin-left: 0;
}

.mainContainer {
  width: 100%;
  display: flex;
  margin-top: 5%;
  flex-wrap: wrap;
}

.leftContainer {
  flex-basis: 30%;
  flex-direction: column;
  display: flex;
  padding-right: 20px;
  justify-content: flex-start;
}

.rightContainer {
  flex-basis: 70%;
}

.year-picker {
  width: 100% !important;
  height: 38px !important;
  border: 1px solid rgba(0, 0, 0, 1) !important;
  margin-bottom: 5px !important;
}

.year-input {
  color: black;
  font-size: 1rem;
}

.btnBenutzer {
  border-radius: 0;
  text-align: left;
  width: 200px;
}

.btnBenutzer:focus,
.btnBenutzer:active:focus,
.btnBenutzer:focus {
  background-color: rgba(230, 230, 230, 1);
}

.bottomContainer {
  margin-top: 13%;
}

.bottomContainer p {
  margin-bottom: 1px;
}

.classicBtn {
  border: 1.4px solid rgba(0, 0, 0, 1);
  font-weight: 500;
  margin-bottom: 5%;
  font-size: 14px;
}

.row {
  margin-bottom: 25px;
}

label {
  margin-bottom: 0.1rem;
  font-size: 14px;
}

input {
  background-color: rgba(222, 222, 222, 0.6) !important;
}

.pwInput {
  width: 60%;
  margin-left: 15px;
  height: 61.5%;
}

.statContainer {
  margin-top: 5%;
}

.headContainer {
  flex-direction: row;
}

@media (max-width: 1700px) {
  .pwInput {
    height: 76%;
  }
}

@media (max-width: 1141px) {
  .classicBtn {
    font-size: 12px;
  }
}

@media (max-width: 976px) {
  .leftContainer {
    flex-basis: 100% !important;
  }

  .rightContainer {
    flex-basis: 100% !important;
    margin-top: 10%;
  }
}

.formControl:focus,
.formControl:active:focus,
.formControl:focus {
  box-shadow: 1px 0 5px 100px rgba(0, 0, 0, 0.8);
  outline: black;
}

.checky {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}

button.activeBtn {
  background-color: rgba(230, 230, 230, 1);
}

input#gb,
.specialInput {
  border: 1px solid #ced4da !important;
  width: 70%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-confirm-alert-body {
  text-align: center !important;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}

.filterBox {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.filterBox button:nth-child(1) {
  margin-bottom: 5px;
}
.filterBox button:not(.nav-button) {
  width: 100%;
}

.nameBoxes {
  border: 1px solid rgba(201, 201, 201, 0.5);
}

.picker-panel {
  z-index: 999;
}

.nameBoxes button {
  width: 100%;
}

.nameBoxes .col {
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.nameBoxes .col button {
  padding: 0;
  height: 40px;
}

.normalp {
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
}

.benutzer {
  background-color: rgba(201, 201, 201, 1);
  color: white;
  padding-left: 5px;
}

.kMXuPO {
  padding-top: 5px;
  padding-bottom: 5px;
}

.gQHdOi div:first-child,
.bgXvAY div:first-child {
  white-space: normal !important;
  text-overflow: inherit !important;
  overflow: visible !important;
}

.mainContainer {
  display: flex;
  width: 100%;
}

.leftContainer {
  flex-basis: 30%;
}

.rightContainer {
  flex-basis: 70%;
}

.basicBtns {
  text-align: left;
  width: 200px;
  border-radius: 0;
}

.basicBtns:focus,
.basicBtns:active:focus,
.basicBtns:focus {
  background-color: rgba(230, 230, 230, 1);
}

.frage {
  width: 100%;
  margin-bottom: 25px;
  background-color: rgba(0,0,0,0.04);
  border: 1px solid rgba(0,0,0,0.1);
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.frage .title-container {
  display: flex;
}

.frage input {
  border: 1px solid rgba(0, 0, 0, 0.9);
}

.frage label {
  font-size: 13px;
}

.drops {
  border: 1px solid rgba(0, 0, 0, 0.9);
  height: 100%;
  background-color: rgba(222, 222, 222, 0.6) !important;
  min-width: 135px;
  color: black;
}

.title-container .boxes {

}

.title-container .boxes:nth-child(1) {
  flex:3 1
}

.title-container .boxes:nth-child(2) {
  margin-left: 10px;
  flex:1 1
}

.common-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.slider-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.frage .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.slider-container, common-container > div {
  flex: 1 1
}

.show > .btn-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.7);
}

#wieOft,
#wieLang {
  border: 1px solid black;
  margin-bottom: 25px;
  padding-left: 10px;
}

.frage .row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.frage .row .col {
  flex: 1 1
}

span.error {
  color: #B22222;
  marginTop: 3;
  font-size: 10px;
}

.frage input.error {
  border: 1px solid #B22222;
}
.topContainer {
  width: 100%;
  text-align: left;
  height: 50px;
  background-color: grey;
  color: white;
  font-weight: 600;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topContainer p {
  margin: 0;
  padding-left: 10px;
}

main {
  width: 70%;
  margin-top: 40px;
}

.midContainer {
  display: flex;
  flex-basis: 100% !important;
  margin-top: 10px;
  height: 40px;
}

.midContainer .underMid {
  flex-basis: 75% !important;
  height: 100%;
  display: flex;
}

.midContainer .underMid input {
  border: 1px solid rgba(0, 0, 0, 1);
  font-size: 14px;
  height: 100%;
  min-height: 40px;
}

.midContainer .tippsBtn {
  flex-basis: 23% !important;
  margin-left: 2%;
  height: 100%;
  min-height: 40px;

  font-size: 14px;
  font-weight: 600;
  min-width: 146px;
}

.iconBtn {
  position: absolute;
  margin-left: 49%;
  margin-top: 7px;
  border: none;
  background: none;
}

@media (max-width: 750px) {
  .midContainer {
    flex-direction: column;
  }

  .midContainer .tippsBtn {
    margin-left: 0;
    width: 50%;
    margin-top: 5px;
  }

  .iconBtn {
    margin-left: 64% !important;
  }
  .mainContainer {
    margin-top: 15% !important;
  }
}

@media (max-width: 1275px) {
  .iconBtn {
    margin-left: 48%;
  }
}

@media (max-width: 930px) {
  .iconBtn {
    margin-left: 46%;
  }
}

@media (max-width: 838px) {
  .iconBtn {
    margin-left: 444%;
  }
}

@media (max-width: 514px) {
  .iconBtn {
    margin-left: 82% !important;
    background-color: rgba(220, 220, 220, 1);
    width: 42px;
    height: 40px;
    margin-top: 0px;
    border-radius: 0 5px 5px 0;
    padding: 6px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }

  main {
    width: 90%;
  }
  .rightContainer {
    flex-basis: 90%;
  }

  th {
    padding-left: 5px !important;
    padding-right: 1px !important;
  }

  .frage .boxes:nth-child(1) {
    width: 60% !important;
  }

  .frage .drops {
    font-size: 14px;
    min-width: 120px;
  }
}

textarea:focus,
input:focus,
input:active,
input:active:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: black !important;
}

.mainContainer {
  margin-top: 10px;
}
table,
tr {
  border: 1px solid rgba(230, 230, 230, 0.5);
}

th {
  background-color: rgba(200, 200, 200, 0.5);
  padding: 10px;
  margin-right: 0px;
  font-weight: 400;
  font-size: 15px;
}

td {
  padding-top: 15px;
  padding: 10px;
  font-size: 14px;
}

.outerBtn {
  padding: 0;
  margin-left: 10px;
  background: none;
  color: black;
  border: none;
}

.outerBtn:hover,
.outerBtn:active,
.outerBtn:focus,
.outerBtn:visited {
  background: transparent !important;
  color: black !important;
}

.insideBoxes .dropdown-toggle:after {
  content: none;
}

.insideBoxes {
  display: flex;
}

/* prettier-ignore */
.insideBoxes .show>.btn-secondary.dropdown-toggle:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.insideBoxes .btn-secondary.focus,
.insideBoxes .btn-secondary:focus,
.insideBoxes .btn-secondary:active,
.insideBoxes .btn-secondary:visited {
  color: black !important;
  background-color: transparent !important;
}

.dropdown-item:active,
.dropdown-toggle:focus {
  background-color: grey !important;
}
.react-confirm-alert-overlay {
  z-index: 9999999;
}

.tippsRow.active {
  background-color: rgb(204, 204, 204);
}

.textNachricht {
  background-color: rgba(200, 200, 200, 0.5);
  width: 70%;
}

.textNachricht:focus {
  background-color: rgba(200, 200, 200, 0.5);
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.authContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outer {
  width: 30%;
  background-color: rgba(190, 190, 190, 1);
  border-radius: 10px;

  min-width: 330px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  align-items: center;
  padding-bottom: 40px;
}

.outer svg {
  margin-top: 30px;
  margin-bottom: 30px;
}

.outer div {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.outer button {
  margin-top: 10px;
  width: 30%;
}

.abmeldeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abmelden {
  height: 200px;
  width: 50%;
  min-width: 350px;
  background-color: rgba(200, 200, 200, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.abmelden div button {
  margin: 10px;
}

.chooseContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}
.chooseContainer p {
  margin-right: 20px;
}

.allCodes {
  background-color: #e0e0e0;
  width: 40%;
  padding: 20px;
  min-width: 300px;
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 20px;
}

.allCodes::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 5px;
}

.allCodes::-webkit-scrollbar-track {
  background: transparent;
  width: 1px;
  border-radius: 5px;
}

.allCodes::-webkit-scrollbar-thumb {
  /* background: rgba(38, 214, 170, 1);
  width: 20px; */
  border-radius: 5px;
}

.allCodes p {
  margin-bottom: 5px;
  margin-top: 5px;
}
.copyCodes {
  width: 40%;
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

